// This file is for mode Form specific functions.
import { globalEventBus } from '../store';
import { showError } from './misc-firestore-utils';
import { t } from './language-utils';


export const modeFormsAreValid = (vueModeFormComponent: any) => {
  let genericModeDataIsValue = false;
  let typedModeFormIsValid = false;

  const genericForm = vueModeFormComponent.$parent.$refs.genericModeDataForm as any;
  genericModeDataIsValue = genericForm ? genericForm.validate() : true;
  const typedForm = vueModeFormComponent.$refs.modeForm as any;
  typedModeFormIsValid = typedForm ? typedForm.validate() : true;

  if (!genericModeDataIsValue || !typedModeFormIsValid) {
    showError(t?.formErrors);
    globalEventBus.$emit('invalidModeForm');
    return false;
  }
  return true;
};

export const getCombinedModeFormData = (vueModeFormComponent: any): Mode & any => {  
  vueModeFormComponent.$parent.saveIsHappening = true;  
  return {
    ...vueModeFormComponent.genericModeDataForm,
    ...vueModeFormComponent.$data.modeForm,
  };
};
