
import Vue, { PropType } from 'vue';
import { globalEventBus } from '@/store';
import { draggableOptions } from '@/constants';
import SitchImageUpload from '@/components/custom-ui-components/SitchImageUpload.vue';
import { generateId } from '@/util-functions/misc-firestore-utils';
import { deepCopy } from '@/util-functions/initialization-utils';

const maxPerPage = 10;

export default Vue.extend({
  components: {
    SitchImageUpload,
  },
  props: {
    listName: {
      type: String,
      required: true,
    },
    nameField: {
      type: String,
      default: 'name',
    },
    iconFunc: {
      type: Function as PropType<(item: any) => string>,
      default: null,
    },
    value: {
      type: Array as PropType<ModeItem[]>,
      default: () => [],
    },
    modeItemFormData: {
      type: Object as PropType<ModeItemFormData>,
      required: true,
    },
    hasMultipleImages: {
      type: Boolean,
      default: false,
    },
    allowReordering: {
      type: Boolean,
      default: true,
    },
    imageSizes: {
      type: Object,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
    onIsHiddenChange: {
      type: Function,
      default: () => undefined,
    },
  },
  data(): {
    draggableOptions: Record<string, unknown>;
    showContent: Record<string, boolean>;
    items: ModeItem[];
    currPage: number;
    maxPerPage: number;
    draggableArray: ModeItem[];
  } {
    return {
      draggableOptions: {
        ...draggableOptions,
        disabled: !this.allowReordering,
      },
      showContent: {},
      items: [],
      currPage: 1,
      maxPerPage,
      draggableArray: [],
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.items.length / maxPerPage);
    },
    currPageOfItems(): ModeItem[] {
      const index = (this.currPage - 1) * maxPerPage;
      return this.items.slice(index, index + maxPerPage);
    },
  },
  watch: {
    currPageOfItems() {
      this.draggableArray = this.currPageOfItems;
      this.$forceUpdate();
    },
    value(oldVal, newVal) {
      this.onLoad();
      if (oldVal.length < newVal.length) {
        this.currPage = this.numberOfPages; // Go to last page;
      }
    },
  },
  mounted() {
    globalEventBus.$on('invalidModeForm', () => {
      // Open accordion slots where there are errors
      Object.keys(this.showContent).forEach((key) => (this.showContent[key] = true));
      this.$forceUpdate();
    });
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.items = [...this.value];
      this.items.forEach((item) => {
        const itemId: string = item.id;
        // Only show content by default if it was a recently added item.
        this.showContent[itemId] = this.showContent[itemId] === undefined ? this.$store.state.idsGeneratedSinceLastModeFormPopulation.includes(itemId) : this.showContent[itemId];
      });
    },
    moveUp(itemId: string) {
      const indexForitemToMove = this.items.findIndex((item) => itemId === item.id);
      const itemToMove = this.items[indexForitemToMove];
      this.items[indexForitemToMove] = this.items[indexForitemToMove - 1];
      this.items[indexForitemToMove - 1] = itemToMove;
      this.onOrderChange();
    },
    moveDown(itemId: string) {
      const indexForitemToMove = this.items.findIndex((item) => itemId === item.id);
      const itemToMove = this.items[indexForitemToMove];
      this.items[indexForitemToMove] = this.items[indexForitemToMove + 1];
      this.items[indexForitemToMove + 1] = itemToMove;
      this.onOrderChange();
    },
    onOrderChange(aDragWasPreformed = false) {
      // Using the arrows lets users move items to different pages. This is not the case with dragging items which updates the draggable array which must then be transplanted into the whole items array.
      if (aDragWasPreformed) {
        const startIndex = (this.currPage - 1) * maxPerPage;
        this.draggableArray.forEach((item, index) => {
          this.items[startIndex + index] = item;
        });
      }
      this.$emit('input', this.items);
    },
    shouldShowContent(item: ModeItem): boolean {
      const itemId: string = item.id;
      return Boolean(this.showContent[itemId]);
    },
    isMarkedForDeletion(item: ModeItem): boolean {
      const itemId: string = item.id;
      return this.modeItemFormData.itemsToBeRemovedById?.includes(itemId);
    },
    onEditToggle(item: ModeItem) {
      const itemId: string = item.id;
      if (!this.showContent[itemId]) {
        Object.keys(this.showContent).forEach((key) => (this.showContent[key] = false));
      }
      if (this.isMarkedForDeletion(item)) {
        return;
      }
      this.showContent[itemId] = !this.showContent[itemId];
      this.$forceUpdate();
    },
    onDelete(item: ModeItem) {
      const itemId: string = item.id;
      this.showContent[itemId] = false;
      this.modeItemFormData.onMarkForDeletion(itemId, this.listName);
    },
    copyItem(item: ModeItem) {      
      const copy = { ...deepCopy(item), id: generateId() };
      this.items.push(copy);
      this.$emit('input', this.items);
    },
  },
});
