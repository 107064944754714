import Vue, { PropType } from 'vue';
import { format } from 'date-fns';
import { getCombinedModeFormData, modeFormsAreValid } from './util-functions/mode-form-utils';
import { deleteImages } from './util-functions/image-utils';
import { eModeType } from './enums';
import validationRules from './validation-rules';
import ModeItemForm from '@/components/custom-ui-components/ModeItemForm.vue';
import { deleteDoc, doc, query } from '@firebase/firestore';
import { collection, limit, onSnapshot, orderBy, where } from 'firebase/firestore';
import { saveMode } from './util-functions/firestore-mode-utils';
import { currFirestore, fbAuth, standardApiFetch } from './util-functions/initialization-utils';
import { t } from './util-functions/language-utils';
import { showLoading, hideLoading } from './util-functions/loading-utils';
import { showError } from './util-functions/misc-firestore-utils';
import { showConfirmation } from './util-functions/notice-utils';
import { deleteFileFromStorage } from './util-functions/storage-utils';
import { getUserModeGatewayDoc } from './util-functions/user-utils';
import { endpoints } from './constants';

interface ModeMixinData {
  genericModeDataForm: Mode;
  rules: typeof validationRules;
  itemsToBeRemovedById: string[];
  arrayPropNamesWithAtLeastOneDeletion: string[];
}

export const modeFormMixin = Vue.extend({
  components: { ModeItemForm },
  props: {
    value: {
      type: Object as PropType<Mode>,
      required: true,
    },
  },
  data(): ModeMixinData {
    return {
      rules: validationRules,
      genericModeDataForm: this.value,
      itemsToBeRemovedById: [],
      arrayPropNamesWithAtLeastOneDeletion: [],
    };
  },
  created() {
    document.addEventListener('keydown', this.onCtrlS, false);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onCtrlS);
  },
  watch: {
    genericModeDataForm(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    resetLink() {
      if (!modeFormsAreValid(this)) {
        return;
      }
      showConfirmation(t?.confirmLinkReset, () => {
        const currUser = fbAuth.currentUser;

        if (!currUser) {
          showError(`No logged in user`);
          return;
        }

        currUser.getIdToken(true).then((idToken) => {
          const resetDocPromises: Promise<any>[] = [];
          if (this.$data.genericModeDataForm.linkId) {
            // Delete old short link.
            resetDocPromises.push(deleteDoc(doc(currFirestore, 'shortPermalinks', this.$data.genericModeDataForm.linkId)));

            // Create new short link.
            resetDocPromises.push(
              standardApiFetch(endpoints.createShortPermalink, {
                idToken,
                userId: this.$store.state.userId,
                modeId: this.$data.genericModeDataForm.docId,
              })
            );

            Promise.all(resetDocPromises)
              .then((results) => {
                this.$data.genericModeDataForm.linkId = (results[1] as SitchApiSuccessfulResponse).successfulResponse.linkId;
                const combinedModeData = getCombinedModeFormData(this) as AnyMode;
                saveMode({ ...combinedModeData });
              })
              .catch((error) => {
                showError(`Could not reset the link`, error, true);
              });
          }
        });
      });
    },
    onCtrlS(e: any) {
      // Ctrl + S save.
      if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
        e.preventDefault();
        (this as any).saveMode();
      }
    },
    modeItemFormData(): ModeItemFormData {
      return {
        onMarkForDeletion: this.onMarkForDeletion,
        onUnmarkForDeletion: this.onUnmarkForDeletion,
        itemsToBeRemovedById: this.itemsToBeRemovedById,
      };
    },
    onMarkForDeletion(itemId: string, arrayPropName: string) {
      if (!this.arrayPropNamesWithAtLeastOneDeletion.includes(arrayPropName)) {
        this.arrayPropNamesWithAtLeastOneDeletion.push(arrayPropName);
      }
      if (this.$store.state.idsGeneratedSinceLastModeFormPopulation.includes(itemId)) {
        const modeFormVal = this.$data.modeForm[arrayPropName];
        const dataVal = this.$data[arrayPropName];

        if (!modeFormVal && !dataVal) {
          showError(`Incorrect propName for items array: ${arrayPropName}`, null, true);
          return;
        }

        const modeFormOrDataObject = modeFormVal ? this.$data.modeForm : this.$data;

        if (modeFormOrDataObject && !Array.isArray(modeFormOrDataObject[arrayPropName])) {
          showError(`Non array propName passed to onMarkForDeletion method`, null, true);
          return;
        }
        modeFormOrDataObject[arrayPropName] = modeFormOrDataObject[arrayPropName].filter((item: any) => (item.id || item.docId) !== itemId);
        this.$forceUpdate();
      } else {
        this.itemsToBeRemovedById.push(itemId);
      }
    },
    onUnmarkForDeletion(itemIdToRemove: string) {
      this.itemsToBeRemovedById = this.itemsToBeRemovedById.filter((itemId) => itemId !== itemIdToRemove);
    },
    // Will first attempt to prune modeForm and if the key does not exist there it will prune $data.
    pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData(): Promise<any[]> {
      const promiseArray: Promise<any>[] = [];
      this.arrayPropNamesWithAtLeastOneDeletion.forEach((arrayPropName) => {
        const modeFormVal = this.$data.modeForm[arrayPropName];
        // By this point we already verified that either modeForm of dataVal exist in onMarkForDeletion.
        const modeFormOrDataObject = modeFormVal ? this.$data.modeForm : this.$data;
        modeFormOrDataObject[arrayPropName] = modeFormOrDataObject[arrayPropName].filter((item: any) => {
          const removeThis = this.$data.itemsToBeRemovedById.includes(item.id || item.docId);
          if (removeThis) {
            if (item.images) {
              promiseArray.push(deleteImages(item.images)); // This function will also delete the images in the storage.
            }
            if (item.secondaryImages) {
              promiseArray.push(deleteImages(item.secondaryImages)); // This function will also delete the images in the storage.
            }            
            if (item.storagePath) {
              // Only for file items.
              promiseArray.push(deleteFileFromStorage(item.storagePath, item.fileName));
            }
          }
          return !removeThis;
        });
      });
      return Promise.all(promiseArray);
    },
    // This is to check what the state of an item array will be after item deletions.
    postDeletionPreviewArray(arrayToCheckForDeletions: { id: string }[]): any[] {
      return arrayToCheckForDeletions.filter((item: any) => {
        return !this.$data.itemsToBeRemovedById.includes(item.id || item.docId);
      });
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      this.pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const combinedModeData = getCombinedModeFormData(this);
          saveMode({ ...combinedModeData });
        })
        .catch((err) => {
          showError(t.somethingWentWrongWhenDeletingAnItem, err, true);
        });
    },
  },
});

export const modeSubmissionsMixin = Vue.extend({
  data(): SubmissionPageMixinData {
    return {
      currSelectedMode: null,
      submissionArray: [],
      dateRangeMenu: false,
      dateRange: [],
      periodSpecified: false,
      currSubmission: null,
      mostRecentSubmission: null,
      showSubmissionDetailsDialog: false,
      watchedCollection: null,
      mostRecentSubmissionDateInMs: 0,
      modeWasJustLoaded: false,
    };
  },
  computed: {
    dateRangeText(): string {
      return this.dateRange.join(' ~ ');
    },
  },
  watch: {
    dateRange() {
      if (this.dateRange.length !== 1) {
        this.getSubmissionsForSelectedMode();
      }
    },
    currSelectedMode() {
      this.getSubmissionsForSelectedMode();
    },
  },
  beforeDestroy() {
    if (this.watchedCollection) {
      this.watchedCollection(); // Detach old listenter if it exists
    }
  },
  methods: {
    showSubmissionDetailsDialogFunc(currSubmission: Submission) {
      this.showSubmissionDetailsDialog = true;
      this.currSubmission = {
        ...currSubmission,
      };
    },
    hideSubmissionDetailsDialogFunc() {
      this.showSubmissionDetailsDialog = false;
      this.currSubmission = null;
    },
    deleteCurrSubmission() {
      const currSelectedMode = this.currSelectedMode;
      const currSubmission = this.currSubmission;

      if (!currSelectedMode) {
        showError(`Cannot delete submission. No mode available.`, null, true);
        return;
      }

      if (!currSubmission) {
        showError(`Cannot delete submission. No submission available.`, null, true);
        return;
      }

      showConfirmation(t?.confirmDeleteSubmission, () => {
        const submissionDoc = doc(currFirestore, getUserModeGatewayDoc().path, 'modes', currSelectedMode.docId, 'submissions', currSubmission.docId);

        deleteDoc(submissionDoc)
          .then(() => {
            this.hideSubmissionDetailsDialogFunc();
          })
          .catch(() => {
            showError(`Could not delete submission.`, null, true);
          });
      });
    },
    formatDate(date: Date): string {
      return format(date, 'MMM dd yyyy h:mm a');
    },
    isRecent(date: number): boolean {
      if (!date) {
        return false;
      }
      return Date.now() - date < 300_000; // 5 minutes.
    },
    onClearDateRange() {
      this.dateRange = [];
      this.dateRangeMenu = false;
    },
    getSubmissionsForSelectedMode() {
      showLoading();
      const periodSpecified = Boolean(this.dateRange.length);

      if (!this.currSelectedMode) {
        hideLoading();
        return;
      }

      if (this.watchedCollection) {
        this.watchedCollection(); // Detach old listenter if it exists
      }

      this.modeWasJustLoaded = true;

      const submissionCollection = collection(currFirestore, getUserModeGatewayDoc().path, 'modes', this.currSelectedMode.docId, 'submissions');

      let q = query(submissionCollection, orderBy('dateCreated', 'desc'), limit(100));

      if (periodSpecified) {
        const firstDateParts = this.dateRange[0].split('-').map((str) => Number(str));
        const secondDateParts = this.dateRange[1].split('-').map((str) => Number(str));
        const start = new Date(firstDateParts[0], firstDateParts[1] - 1, firstDateParts[2]);
        const end = new Date(secondDateParts[0], secondDateParts[1] - 1, secondDateParts[2]);

        q = query(submissionCollection, where('dateCreated', '>=', start), where('dateCreated', '<=', end), orderBy('dateCreated', 'desc'));
      }

      this.watchedCollection = onSnapshot(
        q,
        (querySnapshot) => {
          hideLoading();
          this.submissionArray = [];

          if (this.modeWasJustLoaded) {
            // To stop the modal from popping up once a mode was just selected and the submissions are populating for the first time.
            Vue.nextTick(() => {
              this.modeWasJustLoaded = false;
            });
          }

          querySnapshot.forEach((doc) => {
            querySnapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                const submission = change.doc.data() as Submission;
                this.mostRecentSubmission = submission;

                let shouldShowAlert = false;
                let currAlertObject = null;
                switch (this.currSelectedMode?.type) {
                  case eModeType.booking:
                    shouldShowAlert = this.$store.state.currUser.settings.showNewBookingAlert;
                    currAlertObject = {
                      message: t.newBooking,
                    };
                    break;
                  case eModeType.customForm:
                    shouldShowAlert = this.$store.state.currUser.settings.showNewCustomFormAlert;
                    currAlertObject = {
                      message: t.newFormSubmission,
                    };
                    break;
                }
                if (currAlertObject && shouldShowAlert && this.mostRecentSubmission && !this.modeWasJustLoaded) {
                  this.showSubmissionDetailsDialogFunc(this.mostRecentSubmission);
                  this.$store.commit('currAlertObject', currAlertObject);
                }
              }
            });
            // Overrriding the docId since I was writing the wrong docId until 2022-03-12.
            this.submissionArray.push({ ...doc.data(), docId: doc.id } as Submission);
          });
          hideLoading();
        },
        (error) => {
          showError(`Could not retrieve submissions.`, error, true);
          hideLoading();
        }
      );
    },
  },
});
